/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import t from "../../config.js";
import e from "../../core/Error.js";
import n from "../../core/Logger.js";
import o from "../Polygon.js";
import s from "../Polyline.js";
import { getGeometryParts as r, cutParams as i, offsetMagnitude as l, updatePolyGeometry as c } from "./normalizeUtilsCommon.js";
import { getInfo as f } from "./spatialReferenceUtils.js";
import { geographicToWebMercator as u, webMercatorToGeographic as p } from "./webMercatorUtils.js";
import { cut as a } from "../../rest/geometryService/cut.js";
import { simplify as h } from "../../rest/geometryService/simplify.js";
const m = () => n.getLogger("esri.geometry.support.normalizeUtils");
function g(t) {
  return "polygon" === t.type;
}
function y(t) {
  return "polygon" === t[0].type;
}
function x(t) {
  return "polyline" === t[0].type;
}
function d(t) {
  const e = [];
  let n = 0,
    o = 0;
  for (let s = 0; s < t.length; s++) {
    const r = t[s];
    let i = null;
    for (let t = 0; t < r.length; t++) i = r[t], e.push(i), 0 === t ? (n = i[0], o = n) : (n = Math.min(n, i[0]), o = Math.max(o, i[0]));
    i && e.push([(n + o) / 2, 0]);
  }
  return e;
}
function M(t, n) {
  if (!(t instanceof s || t instanceof o)) {
    const t = "straightLineDensify: the input geometry is neither polyline nor polygon";
    throw m().error(t), new e(t);
  }
  const i = r(t),
    l = [];
  for (const e of i) {
    const t = [];
    l.push(t), t.push([e[0][0], e[0][1]]);
    for (let o = 0; o < e.length - 1; o++) {
      const s = e[o][0],
        r = e[o][1],
        i = e[o + 1][0],
        l = e[o + 1][1],
        c = Math.sqrt((i - s) * (i - s) + (l - r) * (l - r)),
        f = (l - r) / c,
        u = (i - s) / c,
        p = c / n;
      if (p > 1) {
        for (let l = 1; l <= p - 1; l++) {
          const e = l * n,
            o = u * e + s,
            i = f * e + r;
          t.push([o, i]);
        }
        const e = (c + Math.floor(p - 1) * n) / 2,
          o = u * e + s,
          i = f * e + r;
        t.push([o, i]);
      }
      t.push([i, l]);
    }
  }
  return g(t) ? new o({
    rings: l,
    spatialReference: t.spatialReference
  }) : new s({
    paths: l,
    spatialReference: t.spatialReference
  });
}
function w(t, e, n) {
  if (e) {
    const e = M(t, 1e6);
    t = p(e, !0);
  }
  return n && (t = c(t, n)), t;
}
function b(t, e, n) {
  if (Array.isArray(t)) {
    const o = t[0];
    if (o > e) {
      const n = l(o, e);
      t[0] = o + n * (-2 * e);
    } else if (o < n) {
      const e = l(o, n);
      t[0] = o + e * (-2 * n);
    }
  } else {
    const o = t.x;
    if (o > e) {
      const n = l(o, e);
      t = t.clone().offset(n * (-2 * e), 0);
    } else if (o < n) {
      const e = l(o, n);
      t = t.clone().offset(e * (-2 * n), 0);
    }
  }
  return t;
}
function j(t, e) {
  let n = -1;
  for (let o = 0; o < e.cutIndexes.length; o++) {
    const s = e.cutIndexes[o],
      i = e.geometries[o],
      c = r(i);
    for (let t = 0; t < c.length; t++) {
      const e = c[t];
      e.some(n => {
        if (n[0] < 180) return !0;
        {
          let n = 0;
          for (let t = 0; t < e.length; t++) {
            const o = e[t][0];
            n = o > n ? o : n;
          }
          n = Number(n.toFixed(9));
          const o = -360 * l(n, 180);
          for (let s = 0; s < e.length; s++) {
            const e = i.getPoint(t, s);
            i.setPoint(t, s, e.clone().offset(o, 0));
          }
          return !0;
        }
      });
    }
    if (s === n) {
      if (y(t)) for (const e of r(i)) t[s] = t[s].addRing(e);else if (x(t)) for (const e of r(i)) t[s] = t[s].addPath(e);
    } else n = s, t[s] = i;
  }
  return t;
}
async function R(e, n, r) {
  if (!Array.isArray(e)) return R([e], n);
  n && "string" != typeof n && m().warn("normalizeCentralMeridian()", "The url object is deprecated, use the url string instead");
  const p = "string" == typeof n ? n : n?.url ?? t.geometryServiceUrl;
  let g,
    y,
    x,
    d,
    M,
    v,
    P,
    L,
    U = 0;
  const z = [],
    A = [];
  for (const t of e) if (null != t) {
    if (g || (g = t.spatialReference, y = f(g), x = g.isWebMercator, v = x ? 102100 : 4326, d = i[v].maxX, M = i[v].minX, P = i[v].plus180Line, L = i[v].minus180Line), y) {
      if ("mesh" === t.type) A.push(t);else if ("point" === t.type) A.push(b(t.clone(), d, M));else if ("multipoint" === t.type) {
        const e = t.clone();
        e.points = e.points.map(t => b(t, d, M)), A.push(e);
      } else if ("extent" === t.type) {
        const e = t.clone()._normalize(!1, !1, y);
        A.push(e.rings ? new o(e) : e);
      } else if (t.extent) {
        const e = t.extent,
          n = l(e.xmin, M) * (2 * d);
        let o = 0 === n ? t.clone() : c(t.clone(), n);
        e.offset(n, 0);
        let {
          xmin: s,
          xmax: r
        } = e;
        s = Number(s.toFixed(9)), r = Number(r.toFixed(9)), e.intersects(P) && r !== d ? (U = r > U ? r : U, o = w(o, x), z.push(o), A.push("cut")) : e.intersects(L) && s !== M ? (U = r * (2 * d) > U ? r * (2 * d) : U, o = w(o, x, 360), z.push(o), A.push("cut")) : A.push(o);
      } else A.push(t.clone());
    } else A.push(t);
  } else A.push(t);
  let F = l(U, d),
    N = -90;
  const S = F,
    k = new s();
  for (; F > 0;) {
    const t = 360 * F - 180;
    k.addPath([[t, N], [t, -1 * N]]), N *= -1, F--;
  }
  if (z.length > 0 && S > 0) {
    const t = j(z, await a(p, z, k, r)),
      n = [],
      o = [];
    for (let r = 0; r < A.length; r++) {
      const s = A[r];
      if ("cut" !== s) o.push(s);else {
        const s = t.shift(),
          i = e[r];
        null != i && "polygon" === i.type && i.rings && i.rings.length > 1 && s.rings.length >= i.rings.length ? (n.push(s), o.push("simplify")) : o.push(x ? u(s) : s);
      }
    }
    if (!n.length) return o;
    const s = await h(p, n, r),
      i = [];
    for (let e = 0; e < o.length; e++) {
      const t = o[e];
      "simplify" !== t ? i.push(t) : i.push(x ? u(s.shift()) : s.shift());
    }
    return i;
  }
  const C = [];
  for (let t = 0; t < A.length; t++) {
    const e = A[t];
    if ("cut" !== e) C.push(e);else {
      const t = z.shift();
      C.push(!0 === x ? u(t) : t);
    }
  }
  return C;
}
function v(t) {
  if (!t) return null;
  const e = t.extent;
  if (!e) return null;
  const n = t.spatialReference && f(t.spatialReference);
  if (!n) return e;
  const [o, s] = n.valid,
    r = 2 * s,
    {
      width: i
    } = e;
  let l,
    {
      xmin: c,
      xmax: u
    } = e;
  if ([c, u] = [u, c], "extent" === t.type || 0 === i || i <= s || i > r || c < o || u > s) return e;
  switch (t.type) {
    case "polygon":
      if (!(t.rings.length > 1)) return e;
      l = d(t.rings);
      break;
    case "polyline":
      if (!(t.paths.length > 1)) return e;
      l = d(t.paths);
      break;
    case "multipoint":
      l = t.points;
  }
  const p = e.clone();
  for (let f = 0; f < l.length; f++) {
    let t = l[f][0];
    t < 0 ? (t += s, u = Math.max(t, u)) : (t -= s, c = Math.min(t, c));
  }
  return p.xmin = c, p.xmax = u, p.width < i ? (p.xmin -= s, p.xmax -= s, p) : e;
}
function P(t, e, n) {
  const o = f(n);
  if (null == o) return t;
  const [s, r] = o.valid,
    i = 2 * r;
  let l = 0,
    c = 0;
  e > r ? l = Math.ceil(Math.abs(e - r) / i) : e < s && (l = -Math.ceil(Math.abs(e - s) / i)), t > r ? c = Math.ceil(Math.abs(t - r) / i) : t < s && (c = -Math.ceil(Math.abs(t - s) / i));
  let u = t + (l - c) * i;
  const p = u - e;
  return p > r ? u -= i : p < s && (u += i), u;
}
function L(t, e) {
  const n = f(e);
  if (n) {
    const [e, o] = n.valid,
      s = o - e;
    if (t < e) for (; t < e;) t += s;
    if (t > o) for (; t > o;) t -= s;
  }
  return t;
}
export { P as getClosestDenormalizedXToReference, v as getDenormalizedExtent, R as normalizeCentralMeridian, L as normalizeMapX, M as straightLineDensify };