/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import r from "../../request.js";
import { urlToObject as t } from "../../core/urlUtils.js";
import { getJsonType as e } from "../../geometry/support/jsonUtils.js";
import { srToRESTValue as s } from "../../geometry/support/spatialReferenceUtils.js";
import { encodeGeometries as o, decodeGeometries as i } from "./utils.js";
async function m(m, p, f) {
  const n = "string" == typeof m ? t(m) : m,
    a = p[0].spatialReference,
    u = e(p[0]),
    l = {
      ...f,
      query: {
        ...n.query,
        f: "json",
        sr: s(a),
        geometries: JSON.stringify(o(p))
      }
    },
    {
      data: y
    } = await r(n.path + "/simplify", l);
  return i(y.geometries, u, a);
}
export { m as simplify };