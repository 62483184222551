/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../geometry.js";
import e from "../../request.js";
import { getJsonType as t, fromJSON as r } from "../../geometry/support/jsonUtils.js";
import { srToRESTValue as s } from "../../geometry/support/spatialReferenceUtils.js";
import { parseUrl as o } from "../utils.js";
async function i(i, n, p, m) {
  const a = o(i),
    u = n[0].spatialReference,
    c = {
      ...m,
      responseType: "json",
      query: {
        ...a.query,
        f: "json",
        sr: s(u),
        target: JSON.stringify({
          geometryType: t(n[0]),
          geometries: n
        }),
        cutter: JSON.stringify(p)
      }
    },
    f = await e(a.path + "/cut", c),
    {
      cutIndexes: y,
      geometries: g = []
    } = f.data;
  return {
    cutIndexes: y,
    geometries: g.map(e => {
      const t = r(e);
      return t.spatialReference = u, t;
    })
  };
}
export { i as cut };